<template>
 <!-- <div class="header"  >
    <div class="head">
      <div class="logo"><img src="../src/assets/img/logo.png" alt=""></div>
      <nav class="header_nav">
        <li><router-link to="/">首页</router-link></li>
        <li>
          <router-link to="/NetworkPublishing">网络出版</router-link>
          <div class="pop-up">
              <div class="pop-up-content">
                  <div class="recommended"><img src="../src/assets/img/NetworkPublishing1.png" alt=""></div>
                <div class="second_level">
                  <h3>网络出版</h3>
                  <ul class="second_level_list">
                    <li>
                      <div class="second_level_icon">
                        <img src="../src/assets/img/network1.png" alt="">
                      </div>
                       <div class="">
                         <h6>互联网文学</h6>
                         <p>副文本副文本副文本副文本副文本副文本</p>
                       </div>
                    </li>
                    <li>
                      <div class="second_level_icon">
                        <img src="../src/assets/img/hts.png" alt="">
                      </div>
                       <div class="">
                         <h6>互联网图书</h6>
                         <p>副文本副文本副文本副文本副文本副文本</p>
                       </div>
                    </li>
                    <li>
                      <div class="second_level_icon">
                        <img src="../src/assets/img/network2.png" alt="">
                      </div>
                      <div class="">
                        <h6>图书、电子出版</h6>
                        <p>副文本副文本副文本副文本副文本副文本</p>
                      </div>
                    </li>
                    <li>
                      <div class="second_level_icon">
                        <img src="../src/assets/img/network3.png" alt="">
                      </div>
                      <div class="">
                        <h6>手机出版物</h6>
                        <p>副文本副文本副文本副文本副文本副文本</p>
                      </div>
                    </li>
                    <li>
                      <div class="second_level_icon">
                        <img src="../src/assets/img/network4.png" alt="">
                      </div>
                      <div class="">
                        <h6>杂志、教育、学术</h6>
                        <p>副文本副文本副文本副文本副文本副文本</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
        </li>
        <li><router-link to="/Music_art">电子音像</router-link>
          <div class="pop-up">
            <div class="pop-up-content">
              <div class="recommended"><img src="../src/assets/img/header2.png" alt=""></div>
              <div class="second_level">
                <h3>音乐艺术</h3>
                <ul class="second_level_list">
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/yxcbw.png" alt="">
                    </div>
                    <div class="">
                      <h6>互联网音像出版物</h6>
                      <p>副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/yxcb.png" alt="">
                    </div>
                    <div class="">
                      <h6>音像出版物</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </li>
        <li><router-link to="/BlockChain">区块链+</router-link>
          <div class="pop-up">
            <div class="pop-up-content">
              <div class="recommended"><img src="../src/assets/img/header3.png" alt=""></div>
              <div class="second_level">
                <h3>区块链+</h3>
                <ul class="second_level_list">
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/dcl.png" alt="">
                    </div>
                    <div class="">
                      <h6>底层链</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/ggfw.png" alt="">
                    </div>
                    <div class="">
                      <h6>公共服务</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/fxpt.png" alt="">
                    </div>
                    <div class="">
                      <h6>发行平台</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/yylz.png" alt="">
                    </div>
                    <div class="">
                      <h6>应用与流转</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li><router-link to="/Game">游戏+</router-link>
          <div class="pop-up">
            <div class="pop-up-content">
              <div class="recommended"><img src="../src/assets/img/header4.png" alt=""></div>
              <div class="second_level">
                <h3>游戏+</h3>
                <ul class="second_level_list">
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/yxcb_h.png" alt="">
                    </div>
                    <div class="">
                      <h6>游戏出版</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/zbfx1.png" alt="">
                    </div>
                    <div class="">
                      <h6>周边发行</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                  <li>
                    <div class="second_level_icon">
                      <img src="../src/assets/img/zhhd_h.png" alt="">
                    </div>
                    <div class="">
                      <h6>展会活动</h6>
                      <p>副文本副文本副文本副文本副文本副文本</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li><router-link to="/AboutUs">关于我们</router-link></li>
      </nav>
      <div class=""><img src="" alt=""></div>
    </div>
  </div> -->
  <router-view/>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods(){

  },
};
</script>
<style scoped>
@import '../src/assets/css/header.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body,html{
	font-family: "Microsoft YaHei", Arial, sans-serif;
}
</style>
