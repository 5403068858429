import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/footer',
    name: 'footer',
    component: () => import( '../components/Footer.vue')
  }, {
    path: '/header',
    name: 'header',
    component: () => import( '../components/HeaDer.vue')
  },
  {
    path: '/NetworkPublishing',
    name: 'NetworkPublishing',
    component: () => import( '../views/NetworkPublishing.vue')
  },
  {
    path: '/BlockChain',
    name: 'BlockChain',
    component: () => import('../views/BlockChain.vue'),
  },{
    path: '/Game',
    name: 'Game',
    component: () => import('../views/Game.vue'),
  },{
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/Music_art',
    name: 'Music_art',
    component: () => import('../views/Music_art.vue'),
  }, {
    path: '/news_more',
    name: 'news_more',
    component: () => import('../views/News_more.vue'),
  }, {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('../views/NewsDetails.vue'),
  }, {
    path: '/PublicationDetails',
    name: 'PublicationDetails',
    component: () => import('../views/PublicationDetails.vue'),
  }, {
    path: '/MusicDetails',
    name: 'MusicDetails',
    component: () => import('../views/MusicDetails.vue'),
  } ,{
    path: '/institution',
    name: 'institution',
    component: () => import('../views/Institution.vue'),
  },{
    path: '/PublishMore',
    name: 'PublishMore',
    component: () => import('../views/PublishMore.vue'),
  },{
    path: '/MusicMore',
    name: 'MusicMore',
    component: () => import('../views/MusicMore.vue'),
  },{
    path: '/BlockChainmore',
    name: 'BlockChainmore',
    component: () => import('../views/BlockChainmore.vue'),
  },{
    path: '/GameMore',
    name: 'GameMore',
    component: () => import('../views/GameMore.vue'),
  }, 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
