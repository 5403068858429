// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'



// createApp(App).use(store).use(router).mount('#app')
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@element-plus/icons-vue"

import AudioPlayer from '@liripeng/vue-audio-player'

createApp(App).use(AudioPlayer).use(ElementPlus).use(store).use(router).mount('#app')

